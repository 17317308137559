import React from 'react'
import parse from 'html-react-parser'

import ElementImage from '@components/global/element/Image'
import useMedia, { media } from '@hooks/useMedia'
import clsx from 'clsx'

import SvgAnalyze from '@svg/gradient-icons/analyze.inline'
import SvgAudit from '@svg/gradient-icons/audit.inline'
import SvgGoal from '@svg/gradient-icons/goal.inline'
import SvgPaid from '@svg/gradient-icons/paid.inline'
import SvgPersona from '@svg/gradient-icons/persona.inline'
import SvgTiming from '@svg/gradient-icons/timing.inline'
import SvgUnion from '@svg/gradient-icons/union.inline'

import * as cx from './Strategy.module.scss'

const StrategyItem = ({
  content,
  img,
  imgM,
  contentTitleClassName,
  contentClassName,
}) => {
  if (content.type == 0) {
    // text content
    return (
      <li className={cx.overview}>
        <div className={cx.overviewContainer}>
          <div className={cx.overviewIconContainer}>{img}</div>
          <div className={cx.overviewContentContainer}>
            <h1 className={contentTitleClassName}>{content.items.title}</h1>
            <div className={cx.content}>
              {content?.items?.text?.map((item, index, { length }) => {
                return (
                  <>
                    <p key={index} className={contentClassName}>
                      {parse(item)}
                    </p>
                    {index + 1 === length ? null : <br />}
                  </>
                )
              })}
            </div>
          </div>
        </div>
        <hr />
      </li>
    )
  } else {
    // image
    return (
      <li className={cx.overviewImage}>
        <ElementImage
          className={clsx(cx.lastImg, cx.imgMobile)}
          src={imgM}
          alt={'Device Image'}
        />
        <ElementImage
          className={clsx(cx.lastImg, cx.imgTablet)}
          src={img}
          alt={'Device Image'}
        />
      </li>
    )
  }
}

const Strategy = ({
  bgImage,
  img,
  imgM,
  heading,
  headingClassName,
  subheading,
  subheadingClassName,
  desc,
  descClassName,
  content,
  contentTitleClassName,
  contentClassName,
}) => {
  const icons = [
    <SvgGoal key={0} />,
    <SvgAudit key={1} />,
    <SvgUnion key={2} />,
    <SvgPersona key={3} />,
    <SvgPaid key={4} />,
    <SvgTiming key={5} />,
    <SvgAnalyze key={6} />,
  ]

  const items = content?.map((item, index) => {
    return {
      type: 0, // text content
      items: {
        title: item.title,
        text: item.content,
      },
    }
  })

  items.push({ type: 1, img: img, imgM: imgM }) // image

  const grid_render = []
  items?.forEach((item, index, { length }) => {
    grid_render.push(
      <StrategyItem
        key={index}
        content={item}
        img={item.type == 0 ? icons[index] : item.img}
        imgM={item.type == 0 ? icons[index] : item.imgM}
        contentTitleClassName={contentTitleClassName}
        contentClassName={contentClassName}
      />
    )

    if (index % 2 === 1 && index + 1 !== length) {
      grid_render.push(<hr className={cx.separator} />)
    }
  })

  return (
    <section id="strategy" className={cx.wrapper}>
      <div
        className={cx.bannerContainer}
        style={{
          backgroundImage: `url(${bgImage ? bgImage : 'none'})`,
        }}
      >
        <div className={cx.bannerContentContainer}>
          <h1 className={headingClassName}>{heading}</h1>
          <h3 className={subheadingClassName}>{parse(subheading)}</h3>
          <hr />
          <p className={descClassName}>{parse(desc)}</p>
        </div>
      </div>
      <div className={cx.contentContainer}>
        <ul className={cx.list}>{grid_render}</ul>
      </div>
    </section>
  )
}

export default Strategy
