// extracted by mini-css-extract-plugin
export var bannerContainer = "Strategy-module--banner-container--79ccf";
export var bannerContentContainer = "Strategy-module--banner-content-container--f3c06";
export var content = "Strategy-module--content--a651f";
export var contentContainer = "Strategy-module--content-container--4e8af";
export var imgMobile = "Strategy-module--img-mobile--8e3f1";
export var imgTablet = "Strategy-module--img-tablet--58c06";
export var lastImg = "Strategy-module--last-img--724b7";
export var list = "Strategy-module--list--99cd3";
export var overview = "Strategy-module--overview--c30b0";
export var overviewContainer = "Strategy-module--overview-container--8f78e";
export var overviewContentContainer = "Strategy-module--overview-content-container--7bd86";
export var overviewIconContainer = "Strategy-module--overview-icon-container--bfef6";
export var overviewImage = "Strategy-module--overview-image--4041a";
export var separator = "Strategy-module--separator--77210";
export var wrapper = "Strategy-module--wrapper--da361";