import React from 'react'

const SvgChevronRight = ({ className = '', color = '#ffffff' }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`fill-current ${className}`}
    >
      <path
        d="M7.07683 19.2499C7.2636 19.2505 7.44815 19.2117 7.6169 19.1362C7.78566 19.0608 7.93433 18.9506 8.052 18.8139L14.0906 11.7431C14.2745 11.5323 14.375 11.2678 14.375 10.9948C14.375 10.7218 14.2745 10.4573 14.0906 10.2465L7.83946 3.17572C7.62725 2.93506 7.32231 2.78372 6.99172 2.75499C6.66112 2.72626 6.33196 2.82249 6.07665 3.02252C5.82133 3.22255 5.66077 3.50999 5.63029 3.82161C5.59981 4.13322 5.70191 4.44349 5.91412 4.68415L11.5026 11.0007L6.10165 17.3172C5.94877 17.4902 5.85166 17.7009 5.8218 17.9242C5.79195 18.1476 5.8306 18.3744 5.93319 18.5777C6.03578 18.781 6.19801 18.9524 6.40068 19.0715C6.60335 19.1906 6.83799 19.2525 7.07683 19.2499Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgChevronRight
