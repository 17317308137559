import React from 'react'
import clsx from 'clsx'

const Svg = ({ className = '' }) => {
  return (
    <svg
      width="58"
      height="64"
      viewBox="0 0 58 64"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.61 0C41.61 0.951019 41.6998 1.8876 41.8652 2.79404C42.661 6.98424 45.1679 10.5801 48.6504 12.8237C51.0821 14.3989 53.964 15.3054 57.071 15.3054L57.0705 17.7571V26.3012C51.3064 26.3012 45.963 24.4736 41.6097 21.3828V43.7464C41.6097 54.9059 32.4237 64 21.1512 64C16.7982 64 12.7452 62.6326 9.4279 60.3294C4.14452 56.6591 0.692322 50.5964 0.692322 43.7464C0.692322 32.572 9.86354 23.493 21.1361 23.5076C22.0818 23.5076 22.9974 23.582 23.8981 23.701V26.3012L23.8651 26.3168L23.8979 26.3161V34.9347C23.0274 34.6671 22.0967 34.5037 21.1361 34.5037C15.9876 34.5037 11.7997 38.6496 11.7997 43.7464C11.7997 47.2979 13.8411 50.3737 16.8131 51.934C16.8572 51.9936 16.9018 52.0526 16.9471 52.1111C16.9129 52.0459 16.8735 51.9818 16.8283 51.9191C18.1341 52.6027 19.605 52.989 21.1662 52.989C26.1944 52.989 30.3074 49.0216 30.4874 44.0883L30.5024 0H41.61Z"
        fill="url(#paint0_linear_1036_22371)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1036_22371"
          x1="-3.95311"
          y1="64"
          x2="51.2607"
          y2="77.2515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DF6F8" />
          <stop offset="1" stopColor="#6876D5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Svg
