import React from 'react'
import LayoutInnerPage from '@src/layouts/InnerPage'

import TabContent from '@components/pages/social-media-packages/TabContent'
import Overview from '@components/pages/social-media-packages/Overview'
import Strategy from '@components/pages/social-media-packages/Strategy'
import WhatWeCanDo from '@components/pages/social-media-packages/WhatWeCanDo'

import Banner from '@components/global/banner/Banner'
import Seo from '@components/seo/Index'
import Tabs from '@components/global/tabs/DigitalMarketing'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import BlogPostsV2 from '@components/global/blog/BlogPostsV2'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'

import {
  pageData as page,
  bannerData,
  wwcdData,
  tabsData,
  overviewData,
  strategyData,
} from '@src/data/SocialMediaPackages'
import useSocialMediaPackagesMetadata from '@hooks/social-media-packages-metadata'

import * as cx from './SocialMediaPackages.module.scss'

const SocialMediaPackages = () => {
  const {
    bannerBG,
    bannerHres,
    // bannerImg,
    // bannerImgM,
    // bannerImgT,
    tabBG,
    tabImg,
    tabImgM,
    overviewBG,
    wwcdImg1,
    wwcdImg2,
    strategyBG,
    strategyImg,
    strategyImgM,
  } = useSocialMediaPackagesMetadata()

  const modalFunc = React.useRef()

  return (
    <LayoutInnerPage buttonsHeight={page.buttonsHeight}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        layout={bannerData.layout.container}
        wrapperClassName={cx.bannerWrapper}
        contentClassName={cx.bannerContent}
        bgImage={extractImage(bannerBG)}
        breadcrumbs={page.breadcrumbs}
        bannerImage={extractImage(bannerHres)}
        bannerImageClassName={cx.bannerImg}
        headingClassName={cx.bannerHeading}
        title={bannerData.heading.text}
        titleClassName={bannerData.heading.className}
        subtitle2={bannerData.subheading.text}
        subtitle2ClassName={bannerData.subheading.className}
        leftButtonClass={cx.button}
        button_click={(e) => modalFunc.current.openModal()}
        button_text="Learn more"
        button_link="/"
      />
      <ModalForm
        childFunc={modalFunc}
        desktopTitle="Sign Up Today For A<br/> Free Consultation"
        mobileTitle="Sign Up Today For A<br/> Free Consultation"
        description="See how Agent Image can help you grow your real estate business."
        template="m1"
        type="a"
      >
        <FormSignUp
          submitLabel="Get started today"
          messageLabel="Questions or Comments"
        />
      </ModalForm>
      <Tabs
        tabsClassName={tabsData.tabsClassName}
        activeTab={tabsData.activeTab}
      />
      <TabContent
        bgImage={extractImage(tabBG)}
        img={extractImage(tabImg)}
        imgM={extractImage(tabImgM)}
        heading={tabsData.heading}
        headingMobile={tabsData.headingMobile}
        headingClassName={tabsData.headingClassName}
        headingDesc={tabsData.headingDesc}
        headingDescClassName={tabsData.headingDescClassName}
        headingSub={tabsData.headingSub}
        headingSubClassName={tabsData.headingSubClassName}
        benefitsHeader={tabsData.benefitsHeader}
        benefitsHeaderClassName={tabsData.benefitsHeaderClassName}
        benefitsDescList={tabsData.benefitsDescList}
        benefitsDescClassName={tabsData.benefitsDescClassName}
      />
      <WhatWeCanDo
        heading={wwcdData.heading}
        headingClassName={wwcdData.headingClassName}
        img1={extractImage(wwcdImg1)}
        img2={extractImage(wwcdImg2)}
        content={wwcdData.content}
        titleClassName={wwcdData.titleClassName}
        subtitleClassName={wwcdData.subtitleClassName}
        heading1ClassName={wwcdData.heading1ClassName}
        subheading1ClassName={wwcdData.subheading1ClassName}
        heading2ClassName={wwcdData.heading2ClassName}
        subheading2ClassName={wwcdData.subheading2ClassName}
        button_click={(e) => modalFunc.current.openModal()}
      />
      <Overview
        bgImage={extractImage(overviewBG)}
        heading={overviewData.heading}
        headingClassName={overviewData.headingClassName}
        title={overviewData.title}
        titleClassName={overviewData.titleClassName}
        subtitle={overviewData.subtitle}
        subtitleClassName={overviewData.subtitleClassName}
        content={overviewData.content}
        contentTitleClassName={overviewData.contentTitleClassName}
        contentBoldClassName={overviewData.contentBoldClassName}
        contentClassName={overviewData.contentClassName}
      />
      <Strategy
        bgImage={extractImage(strategyBG)}
        img={extractImage(strategyImg)}
        imgM={extractImage(strategyImgM)}
        heading={strategyData.heading}
        headingClassName={strategyData.headingClassName}
        subheading={strategyData.subheading}
        subheadingClassName={strategyData.subheadingClassName}
        desc={strategyData.desc}
        descClassName={strategyData.descClassName}
        content={strategyData.content}
        contentTitleClassName={strategyData.contentTitleClassName}
        contentClassName={strategyData.contentClassName}
      />
      <BlogPostsV2 />
      <LeadContactForm
        version="LCF08"
        modelOrBackground="CutOutKatie"
        title="Want a strong social presence?"
        subtitle="Consult with us to know more about our social media packages."
        submitLabel="Book My Schedule"
        // titleClassName="heading-3"
        leadName="Lead Contact Form"
        layout="default"
      />
    </LayoutInnerPage>
  )
}

export default SocialMediaPackages
