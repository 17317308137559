// extracted by mini-css-extract-plugin
export var container = "Overview-module--container--c047d";
export var content = "Overview-module--content--38bc8";
export var descList = "Overview-module--desc-list--79e15";
export var header = "Overview-module--header--1f70b";
export var img = "Overview-module--img--53a40";
export var overview = "Overview-module--overview--2f989";
export var overviewBg = "Overview-module--overview-bg--49db2";
export var overviewContent = "Overview-module--overview-content--4a236";
export var overviewContentContainer = "Overview-module--overview-content-container--caf81";
export var wrapper = "Overview-module--wrapper--8dddc";