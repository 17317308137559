import React from 'react'
import clsx from 'clsx'

const Svg = ({ className = '' }) => {
  return (
    <svg
      width="80"
      height="64"
      viewBox="0 0 80 64"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.3443 7.38462C76.3934 8.86154 73.4426 9.35385 70 9.84615C73.4426 7.87692 75.9016 4.92308 76.8852 0.984614C73.9344 2.95385 70.4918 3.93846 66.5574 4.92308C63.6065 1.96923 59.1803 0 54.7541 0C44.4262 0 36.5574 9.84615 39.0164 19.6923C25.7377 19.2 13.9344 12.8 5.57376 2.95385C1.14753 10.3385 3.60655 19.6923 10.4918 24.6154C8.03278 24.6154 5.57376 23.6308 3.11475 22.6462C3.11475 30.0308 8.52459 36.9231 15.9016 38.8923C13.4426 39.3846 10.9836 39.8769 8.52459 39.3846C10.4918 45.7846 16.3934 50.7077 23.7705 50.7077C17.8688 55.1385 9.01639 57.6 0.655731 56.6154C8.03278 61.0462 16.3934 64 25.2459 64C55.2459 64 71.9672 38.8923 70.9836 15.7538C74.4262 13.7846 77.377 10.8308 79.3443 7.38462Z"
        fill="url(#paint0_linear_1036_22404)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1036_22404"
          x1="-5.82797"
          y1="64"
          x2="67.4511"
          y2="88.5467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DF6F8" />
          <stop offset="1" stopColor="#6876D5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Svg
