import React from 'react'
import clsx from 'clsx'

const Svg = ({ className = '' }) => {
  return (
    <svg
      width="63"
      height="77"
      viewBox="0 0 63 77"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3988 4.8996H54.5994C56.826 4.90319 58.9608 5.78879 60.5358 7.3638C62.1108 8.9388 62.9964 11.0736 63 13.3002V42.6996C62.9964 44.9262 62.1108 47.061 60.5358 48.636C58.9608 50.211 56.826 51.0966 54.5994 51.1002H8.3988V72.1008H10.4994C11.6586 72.1008 12.6 73.0386 12.6 74.1996C12.6 75.3606 11.6586 76.3002 10.4994 76.3002H2.0988C0.9396 76.3002 0 75.3588 0 74.1996C0 73.0404 0.9396 72.1008 2.0988 72.1008H4.1994V2.8008C4.1994 1.6398 5.1408 0.700195 6.3 0.700195C7.4592 0.700195 8.3988 1.6398 8.3988 2.8008V4.8996ZM57.5676 45.6696C58.356 44.8812 58.7988 43.8138 58.7988 42.6996H58.8006V13.302C58.7988 12.1878 58.3578 11.1204 57.5694 10.332C56.781 9.54359 55.7136 9.10079 54.5994 9.10079H8.3988V46.9008H54.5976C55.7118 46.9008 56.7792 46.458 57.5676 45.6696ZM31.2732 33.2046L42.417 18.3402V18.342C43.1136 17.4132 44.4294 17.2242 45.3582 17.919C46.287 18.6138 46.476 19.9314 45.7812 20.8602L33.1812 37.6596C32.814 38.1474 32.256 38.4516 31.6476 38.4966C31.599 38.5002 31.5486 38.5002 31.5 38.5002C30.9438 38.4984 30.4092 38.277 30.015 37.8846L21.6144 29.484C20.8188 28.6614 20.8296 27.351 21.6396 26.541C22.4496 25.731 23.76 25.7202 24.5844 26.5158L31.2732 33.2046Z"
        fill="url(#paint0_linear_1036_22630)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1036_22630"
          x1="-5.19101"
          y1="76.3002"
          x2="56.8626"
          y2="90.3888"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DF6F8" />
          <stop offset="1" stopColor="#6876D5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Svg
