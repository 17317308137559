// extracted by mini-css-extract-plugin
export var button = "WhatWeCanDo-module--button--7b854";
export var checkSvg = "WhatWeCanDo-module--check-svg--cd70a";
export var content = "WhatWeCanDo-module--content--e07a3";
export var contentContainer = "WhatWeCanDo-module--content-container--12335";
export var contentHeading = "WhatWeCanDo-module--content-heading--7e4b5";
export var contentList = "WhatWeCanDo-module--content-list--22fc8";
export var desc1 = "WhatWeCanDo-module--desc1--5184a";
export var desc1Icon = "WhatWeCanDo-module--desc1-icon--b2543";
export var desc1Text = "WhatWeCanDo-module--desc1-text--be958";
export var desc2 = "WhatWeCanDo-module--desc2--79d40";
export var desc2Icon = "WhatWeCanDo-module--desc2-icon--ead2f";
export var desc2Text = "WhatWeCanDo-module--desc2-text--3376b";
export var heading = "WhatWeCanDo-module--heading--67b02";
export var imageContainer = "WhatWeCanDo-module--image-container--52def";
export var img = "WhatWeCanDo-module--img--b1150";
export var item = "WhatWeCanDo-module--item--2ae83";
export var shIcons = "WhatWeCanDo-module--sh-icons--7631e";
export var subheading2 = "WhatWeCanDo-module--subheading2--844c3";
export var wrapper = "WhatWeCanDo-module--wrapper--3e48d";