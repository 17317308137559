import React from 'react'
import clsx from 'clsx'

const SvgVideo = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 32 32' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08 3C4.83579 3 3 4.83579 3 7.08V24.39C3 26.6598 4.83141 28.5 7.08 28.5H24.39C26.6598 28.5 28.5 26.6686 28.5 24.42V7.08C28.5 4.83579 26.6642 3 24.42 3H7.08ZM5.7 7.08C5.7 6.32421 6.32421 5.7 7.08 5.7H24.39C25.1458 5.7 25.77 6.32421 25.77 7.08V24.7044C25.6371 25.3262 25.0785 25.8 24.42 25.8H7.08C6.32421 25.8 5.7 25.1758 5.7 24.42V7.08ZM15.8704 12.0296C15.5104 11.6696 14.9404 11.5496 14.4604 11.7596C13.9804 11.9696 13.6504 12.4196 13.6504 12.9596V18.5696C13.6504 19.1096 13.9804 19.5596 14.4604 19.7696C14.6104 19.8296 14.7904 19.8596 14.9404 19.8596C15.2704 19.8596 15.6004 19.7096 15.8704 19.4696L18.6304 16.6196C19.1104 16.1096 19.1104 15.2996 18.6304 14.7896L15.8704 12.0296Z"
      />
    </svg>
  )
}

export default SvgVideo
