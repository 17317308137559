import React from 'react'
import clsx from 'clsx'

const SvgHomeEdit = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 32 32' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <g clipPath="url(#clip0_82_3077)">
        <path
          d="M24.7273 25.3437C24.7273 25.633 24.6123 25.9105 24.4078 26.1151C24.2032 26.3196 23.9257 26.4346 23.6364 26.4346H8.36364C8.07431 26.4346 7.79683 26.3196 7.59225 26.1151C7.38766 25.9105 7.27273 25.633 7.27273 25.3437V15.5255H4L15.2658 5.28403C15.4667 5.10127 15.7285 5 16 5C16.2715 5 16.5333 5.10127 16.7342 5.28403L28 15.5255H24.7273V25.3437ZM22.5455 24.2528V13.5149L16 7.56512L9.45455 13.5149V24.2528H22.5455ZM16 22.0709L12.3356 18.4066C12.1077 18.1786 11.9269 17.9081 11.8036 17.6103C11.6802 17.3125 11.6167 16.9933 11.6167 16.6709C11.6167 16.3486 11.6802 16.0294 11.8036 15.7316C11.9269 15.4338 12.1077 15.1632 12.3356 14.9353C12.5636 14.7074 12.8342 14.5266 13.132 14.4032C13.4298 14.2799 13.7489 14.2164 14.0713 14.2164C14.3936 14.2164 14.7128 14.2799 15.0106 14.4032C15.3084 14.5266 15.579 14.7074 15.8069 14.9353L16 15.1284L16.1931 14.9353C16.421 14.7074 16.6916 14.5266 16.9894 14.4032C17.2872 14.2799 17.6064 14.2164 17.9287 14.2164C18.2511 14.2164 18.5702 14.2799 18.868 14.4032C19.1658 14.5266 19.4364 14.7074 19.6644 14.9353C19.8923 15.1632 20.0731 15.4338 20.1964 15.7316C20.3198 16.0294 20.3833 16.3486 20.3833 16.6709C20.3833 16.9933 20.3198 17.3125 20.1964 17.6103C20.0731 17.9081 19.8923 18.1786 19.6644 18.4066L16 22.0709Z"
          fill="#A5A5AE"
        />
      </g>
      <defs>
        <clipPath id="clip0_82_3077">
          <rect width="32" height="32" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgHomeEdit
