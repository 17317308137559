import React from 'react'
import clsx from 'clsx'

const SvgLike = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 32 32' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M8.5 28C9.60826 28 10.6777 27.5882 11.5 26.8449C12.3223 27.5875 13.3917 27.9993 14.5 28H21.25C22.0743 28 22.8792 27.7529 23.5616 27.2908C24.244 26.8295 24.7723 26.1732 25.079 25.4085L27.6257 19.0636C28.3114 17.3487 28.0931 15.404 27.0444 13.8833C25.9958 12.3625 24.256 11.4679 22.4092 11.4993H22.1614L22.4989 10.4868C22.9984 8.98348 22.7467 7.33214 21.8199 6.0471C20.8937 4.76272 19.4065 4.00067 17.8221 4H17.4993C16.4962 3.99933 15.5587 4.50022 15.0022 5.3346L10.75 11.7056C10.5618 11.9935 10.4071 12.3016 10.2886 12.6243C8.89844 12.0217 7.29866 12.1596 6.0317 12.9906C4.7654 13.8223 4.00134 15.2346 4 16.75V23.5C4 24.6933 4.47411 25.8377 5.31786 26.6821C6.16161 27.5266 7.30603 28 8.5 28ZM13 16.75V14.204C13 13.9094 13.0877 13.6208 13.2511 13.375L17.5 7H17.8228C18.4422 7.00134 19.0241 7.29933 19.3864 7.80156C19.7487 8.30447 19.8478 8.95067 19.6529 9.53862L18.2915 13.6261C18.2239 13.8283 18.258 14.0513 18.3826 14.2241C18.5071 14.3969 18.708 14.5 18.9217 14.5H22.4132C23.2797 14.4866 24.0953 14.9092 24.5842 15.625C25.0408 16.3141 25.1299 17.1826 24.8246 17.95L22.2859 24.2915C22.2036 24.4991 22.0609 24.6772 21.8761 24.8031C21.692 24.9297 21.4743 24.998 21.2507 25H14.5007C14.1029 25 13.7212 24.842 13.44 24.5607C13.1587 24.2795 13.0007 23.8978 13.0007 23.5L13 16.75ZM7 16.75C7 16.2143 7.28594 15.7188 7.75 15.4509C8.21406 15.183 8.78594 15.183 9.25 15.4509C9.71406 15.7188 10 16.2136 10 16.75V23.5C10 24.0357 9.71406 24.5312 9.25 24.7991C8.78594 25.067 8.21406 25.067 7.75 24.7991C7.28594 24.5312 7 24.0364 7 23.5V16.75Z" />
    </svg>
  )
}

export default SvgLike
