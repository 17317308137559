import { useStaticQuery, graphql } from 'gatsby'

const useSocialMediaPackagesMetadata = () => {
  const query = useStaticQuery(graphql`
    query socialMediaPackagesMetadata {
      bannerBG: file(
        relativePath: { eq: "social-media-packages/banner-bg.png" }
      ) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      bannerHres: file(
        relativePath: { eq: "social-media-packages/banner-hres.png" }
      ) {
        id
        ...SocialMediaPackagesImageOptimizeHres
      }
      # bannerImg: file(
      #   relativePath: { eq: "social-media-packages/banner-image.png" }
      # ) {
      #   id
      #   ...SocialMediaPackagesImageOptimize
      # }
      # bannerImgT: file(
      #   relativePath: { eq: "social-media-packages/banner-image-tablet.png" }
      # ) {
      #   id
      #   ...SocialMediaPackagesImageOptimize
      # }
      # bannerImgM: file(
      #   relativePath: { eq: "social-media-packages/banner-image-mobile.png" }
      # ) {
      #   id
      #   ...SocialMediaPackagesImageOptimize
      # }
      tabBG: file(relativePath: { eq: "social-media-packages/tab-bg.png" }) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      tabImg: file(
        relativePath: { eq: "social-media-packages/tab-image.png" }
      ) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      tabImgM: file(
        relativePath: { eq: "social-media-packages/tab-image-mobile.png" }
      ) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      overviewBG: file(
        relativePath: { eq: "social-media-packages/overview-bg.png" }
      ) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      strategyBG: file(
        relativePath: { eq: "social-media-packages/strategy-bg.png" }
      ) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      strategyImg: file(
        relativePath: { eq: "social-media-packages/strategy-image.png" }
      ) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      strategyImgM: file(
        relativePath: { eq: "social-media-packages/strategy-image-mobile.png" }
      ) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      wwcdImg1: file(relativePath: { eq: "social-media-packages/wwcd-1.png" }) {
        id
        ...SocialMediaPackagesImageOptimize
      }
      wwcdImg2: file(relativePath: { eq: "social-media-packages/wwcd-2.png" }) {
        id
        ...SocialMediaPackagesImageOptimize
      }
    }

    fragment SocialMediaPackagesImageOptimize on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 90
          formats: [AUTO, WEBP]
        )
      }
    }

    fragment SocialMediaPackagesImageOptimizeHres on File {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          formats: [AUTO, WEBP]
        )
      }
    }
  `)

  return query
}

export default useSocialMediaPackagesMetadata
