import React from 'react'
import clsx from 'clsx'

const SvgAnalytics = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 32 32' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M5.71429 17.7139H9.14286C10.0896 17.7139 10.8571 18.6606 10.8571 19.4282V26.2853C10.8571 27.2321 10.0896 27.9996 9.14286 27.9996H5.71429C4.76751 27.9996 4 27.0528 4 26.2853V19.4282C4 18.4814 4.76751 17.7139 5.71429 17.7139Z" />
      <path d="M14.2856 10.8574H17.7141C18.6609 10.8574 19.4284 11.8042 19.4284 12.5717V26.286C19.4284 27.2328 18.6609 28.0003 17.7141 28.0003H14.2856C13.3388 28.0003 12.5713 27.0535 12.5713 26.286V12.5717C12.5713 11.6249 13.3388 10.8574 14.2856 10.8574Z" />
      <path d="M22.8569 4H26.2854C27.2322 4 27.9997 4.94678 27.9997 5.71429V26.2857C27.9997 27.2325 27.2322 28 26.2854 28H22.8569C21.9101 28 21.1426 27.0532 21.1426 26.2857V5.71429C21.1426 4.76751 21.9101 4 22.8569 4Z" />
    </svg>
  )
}

export default SvgAnalytics
