export const pageData = {
  title: 'Real Estate Social Media Packages',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Digital Marketing',
      url: '/real-estate-digital-marketing/',
    },
    {
      text: 'Real Estate Social Media Packages',
      url: '/real-estate-digital-marketing/real-estate-social-media-packages/',
    },
  ],
  uri: '/real-estate-digital-marketing/real-estate-social-media-packages/',
  buttonsHeight: { desktop: 1880, tablet: 2200, phone: 2880 },
  lcf: {
    titleClassName: 'heading-2',
  },
}

export const bannerData = {
  layout: { container: 'product-intro-bg' },
  heading: {
    text: 'Real Estate Social Media Packages',
    className: 'heading-1',
  },
  subheading: {
    text: 'Get a strong social presence for your real estate business',
    className: 'subtitle-7',
  },
}

export const tabsData = {
  activeTab: 1,
  tabsClassName: 'small-heading',
  heading: 'Social Media Packages<br/> For Real Estate Professionals',
  headingMobile: 'Social Media Packages<br/> For Real Estate Professionals',
  headingClassName: 'heading-5',
  headingDesc:
    'There is no denying that social media has<br/> become one of the most used platforms in the<br/> digital world. This is where you humanize your<br/> brand in order to make personal connections<br/> with your audience. Don’t be left behind in an<br/> industry where over 77% of active realtors* are<br/> using social media to help boost their business.',
  headingDescClassName: 'subtitle-5',
  headingSub: '*National Association of Realtors, 2020',
  headingSubClassName: 'small-heading',
  benefitsHeader: 'Benefits of Real Estate Social<br/> Media Management',
  benefitsHeaderClassName: 'heading-3',
  benefitsDescList: [
    'Widen Your Market',
    'Generate More Web Traffic & Leads',
    'Precision Target High-Intent Audiences',
    'Increase Engagement (Likes, Comments & Shares)',
    'Boost Brand Awareness',
    'Get First-Hand Consumer Insights With Analytics',
  ],
  benefitsDescClassName: 'subtitle-6',
}

export const wwcdData = {
  heading: 'What We Can Do',
  headingClassName: 'heading-2',
  content: [
    {
      title: 'Social Media Presence',
      subtitle:
        'Get your social presence off to a great start by publishing branded organic content on Facebook and Twitter.',
      heading1: 'Best For',
      subheading1:
        'Agents who are new to social media and want to start small but strong.',
      heading2: 'Highlights',
      subheading2: [
        'Custom Facebook & Twitter page design',
        '5x branded posts per week on Facebook & Twitter',
        '2x listing videos shared on your timeline',
      ],
    },
    {
      title: 'Social Media Reach',
      subtitle:
        'Reach the right audience through a combination of organic and paid posts — guided by your very own social media playbook.',
      heading1: 'Best For',
      subheading1:
        'Agents who want to scale up their brand building and lead generation through social media.',
      heading2: 'Inclusions',
      subheading2: [
        'Organic and paid posts for Facebook, Instagram, Twitter, and LinkedIn',
        'Custom-made social media playbook and advertising strategy ',
        'Competitor analysis report',
        'Chatbot auto-responders',
      ],
    },
  ],
  titleClassName: 'heading-3',
  subtitleClassName: 'subtitle-5',
  heading1ClassName: 'subtitle-1',
  subheading1ClassName: 'subtitle-5',
  heading2ClassName: 'subtitle-1',
  subheading2ClassName: 'subtitle-5',
}

export const overviewData = {
  heading: 'Social Media Marketing',
  headingClassName: 'subtitle-1',
  title: 'An Overview of Major Platforms',
  titleClassName: 'heading-2',
  subtitle:
    'With so many social media platforms out there, how do you know which ones to use? Check out this guide we prepared.',
  subtitleClassName: 'subtitle-5',
  content: [
    {
      title: 'Facebook',
      contentBold:
        'Facebook is the leading social media platform with more than 1.93 billion<br/> daily active users.',
      content:
        'Slightly more Facebook users are male (56% to 44%) and<br/> 69% of US adults use Facebook. Realtors should not that 75% of these users<br/> make over $75,000 per year, making Facebook advertising a valuable strategy.',
    },
    {
      title: 'Instagram',
      contentBold:
        'Owned by Facebook, Instagram is a very visual platform so good property<br/> photos are very important.',
      content:
        'Instagram Stories target a younger demographic<br/> between 18 and 34 years old, roughly half male and half female. About 60% of<br/> Instagram users make over $100,000 a year. Sponsored Instagram Stories often<br/> utilize local targeting, carousel ads and hashtags.',
    },
    {
      title: 'TikTok',
      contentBold:
        'TikTok is a new medium that is very popular with the younger set.',
      content:
        'Because it<br/> is a highly visual medium focused on videos, TikTok is perfect for real estate<br/> social media marketing. Engaging videos are a must so consider getting help<br/> with your content creation.',
    },
    {
      title: 'Twitter',
      contentBold:
        'Twitter is a microblogging platform that’s primarily used by 18 to 49-year-<br/>olds.',
      content:
        'Users can share captions of up 280 characters and attach both photos and<br/> videos to their posts. Twitter is often used in marketing as a customer service<br/> platform because of its speedy, “in-the-moment” nature.',
    },
    {
      title: 'Pinterest',
      contentBold:
        'Pinterest acts like an online bulletin board where the pinner can place<br/> photos and ideas.',
      content:
        'Others can then see these posts if a board is made public.<br/> According to Pinterest, it is a great platform to reach women over 35 who have<br/> a household income of over $100,000 per year. About 433 million people a<br/> month use Pinterest.',
    },
    {
      title: 'LinkedIn',
      contentBold: 'This is social media for business-minded folks.',
      content:
        'Linkedin is great for<br/> networking with other professionals and growing your personal brand. It’s also<br/> an ideal place to connect with real estate industry leaders and decision-makers.',
    },
  ],
  contentTitleClassName: 'subtitle-2',
  contentBoldClassName: 'default-body large',
  contentClassName: 'default-body large',
}

export const strategyData = {
  heading: 'Social Media Strategy',
  headingClassName: 'heading-2',
  subheading: 'For More Engagement<br/> – And More Leads',
  subheadingClassName: 'subtitle-1',
  desc:
    'Our social media packages are grounded in strategy to ensure that each organic and sponsored content we post<br/> builds your business.',
  descClassName: 'subtitle-5',
  content: [
    {
      title: 'Clarify Your Goal',
      content: [
        'Identify your social media marketing vision and how this supports your<br/> current goals. What do you want to achieve and how will you measure<br/> success? Most real estate agents want to generate leads and this is likely<br/> your end goal, too.',
      ],
    },
    {
      title: 'Do A Social Media Marketing Audit',
      content: [
        'Take a look at several of your top competitors’ social channels. See what they<br/> do well and what is done poorly. Then compare it to your channel. Look at<br/> content, engagement, frequency, audience, visuals, tone, and customer<br/> service messaging. Your goal is to get engagement and lead generation that<br/> surpasses theirs.',
      ],
    },
    {
      title: 'Focus On Your Objectives',
      content: [
        'Review each of your social media platforms and ask what are your goals,<br/> strategy, objectives, and tactics (GSOTs). How is your present social strategy<br/> filling your GSOTs? Where can you improve? How can you become the agent<br/> others recommend?',
      ],
    },
    {
      title: 'Develop A Brand Persona',
      content: [
        'Develop a persona whose voice embodies your brand. Start by asking how<br/> you want your brand to be perceived by clients. Then decide on three to five<br/> values that are most important to you and use these to shape your persona.<br/> There are templates online that can help guide you through this process.',
      ],
    },
    {
      title: 'Paid, Earned, And Owned Strategies',
      content: [
        'Use paid advertising on your social media marketing platforms sparingly – no<br/> more than once a week and pick only the most popular post to boost.',
        'Develop a hashtag and use it on every post. If you have a well-established<br/> business and your target audience knows you by name, feel free to turn your<br/> name into a hashtag, too.',
        'Finally, look for relevant keywords and keyword phrases that can give your<br/> posts greater visibility. By anticipating the search phrases people use and<br/> turning them into hashtags, your posts have a higher likelihood of reaching<br/> new audiences.',
      ],
    },
    {
      title: 'Timing And Dates',
      content: [
        'Post content that is applicable to your real estate clients – real estate trends,<br/> professionally written articles about prepping your home for sale, etc. Post on<br/> holidays and local events to tie yourself to the community. Consider adding<br/> local news stories that bring out the good sides of your region. Commit to<br/> lead generation by focusing your marketing around the marketing trends in your local area.',
        'Post real estate content and coordinate your social media content with the<br/> calendar. You can link Facebook, Instagram, and Twitter so that similar stories<br/> will post at the same time.',
      ],
    },
    {
      title: 'Analyzing The Data',
      content: [
        'Finally, you need to see what is working. Facebook and Google Analytics will<br/> let you know how engaging your posts are. Drop those that don’t get traction<br/> and double up on ones that do.',
        'As you can see, your social strategy is as complex as your business plan and<br/> every bit as important. Your time is money and there is no point in throwing it<br/> away. Consider using a professional social media marketing company to<br/> enhance your online presence.',
      ],
    },
  ],
  contentTitleClassName: 'subtitle-9',
  contentClassName: 'default-body large',
}
