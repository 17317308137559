import React from 'react'
import parse from 'html-react-parser'

import ElementImage from '@components/global/element/Image'
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import SvgAnalytics from '@svg/analytics.inline'
import SvgChat from '@svg/chat.inline'
import SvgEdit from '@svg/edit.inline'
import SvgHomeHeart from '@svg/homeHeart.inline'
import SvgLike from '@svg/like.inline'
import SvgVideo from '@svg/video.inline'
import useMedia, { media } from '@hooks/useMedia'

import * as cx from './WhatWeCanDo.module.scss'
import clsx from 'clsx'

const WWCDItem = ({
  content,
  image,
  imageID,
  icons,
  titleClassName,
  subtitleClassName,
  heading1ClassName,
  subheading1ClassName,
  heading2ClassName,
  subheading2ClassName,
  button_click,
}) => {
  /* const tablet = useMedia(media.minTablet)
  const desktop = useMedia(media.desktop)
  let cWidth = 414
  let cHeight = 347.5

  if (desktop) {
    cWidth = 697
    cHeight = 585
  } else if (tablet) {
    cWidth = 456
    cHeight = 414
  } else {
    cWidth = 414
    cHeight = 347.5
  } */

  return (
    <li className={cx.item}>
      <div className={cx.content}>
        <div className={cx.imageContainer}>
          <canvas width={697} height={585}></canvas>
          <ElementImage
            src={image}
            alt={'Device Image'}
            className={clsx(cx.img, imageID)}
          />
        </div>
        <div className={cx.contentContainer}>
          <div className={cx.contentHeading}>
            <h1 className={titleClassName}>{content.title}</h1>
            <p className={subtitleClassName}>{content.subtitle}</p>
          </div>
          <div className={cx.desc1}>
            <h3 className={heading1ClassName}>{content.heading1}</h3>
            <div className={cx.desc1Text}>
              <div className={cx.desc1Icon}>
                <SvgCheckedCircle className={cx.checkSvg} />
              </div>
              <p className={subheading1ClassName}>{content.subheading1}</p>
            </div>
          </div>
          <div className={cx.desc2}>
            <h3 className={heading2ClassName}>{content.heading2}</h3>
            <div className={cx.desc2Text}>
              {content.subheading2?.map((item, index) => {
                return (
                  <div key={index} className={cx.subheading2}>
                    <div className={cx.desc2Icon}>{icons[index]}</div>
                    <p className={subheading2ClassName}>{parse(item)}</p>
                  </div>
                )
              })}
            </div>
          </div>
          <button
            className={clsx('primary-button', cx.button)}
            onClick={button_click}
          >
            Get Pricing
          </button>
        </div>
      </div>
      <hr />
    </li>
  )
}

const WhatWeCanDo = ({
  heading,
  headingClassName,
  img1,
  img2,
  content,
  titleClassName,
  subtitleClassName,
  heading1ClassName,
  subheading1ClassName,
  heading2ClassName,
  subheading2ClassName,
  button_click,
}) => {
  const assets = [
    {
      image: img1,
      icons: [
        <SvgEdit key={0} className={cx.shIcons} />, //icon too small
        <SvgLike key={1} className={cx.shIcons} />, //icon too small
        <SvgVideo key={2} className={cx.shIcons} />,
      ],
    },
    {
      image: img2,
      icons: [
        <SvgLike key={0} className={cx.shIcons} />,
        <SvgHomeHeart key={1} className={cx.shIcons} />,
        <SvgAnalytics key={2} className={cx.shIcons} />,
        <SvgChat key={3} className={cx.shIcons} />,
      ],
    },
  ]

  return (
    <section id="tabs-content" className={cx.wrapper}>
      <div className={cx.heading}>
        <h1 className={headingClassName}>{parse(heading)}</h1>
      </div>
      <ul className={cx.contentList}>
        {content?.map((item, index) => {
          const imgID = 'img' + index
          return (
            <WWCDItem
              key={index}
              content={item}
              image={assets[index].image}
              imageID={imgID}
              icons={assets[index].icons}
              titleClassName={titleClassName}
              subtitleClassName={subtitleClassName}
              heading1ClassName={heading1ClassName}
              subheading1ClassName={subheading1ClassName}
              heading2ClassName={heading2ClassName}
              subheading2ClassName={subheading2ClassName}
              button_click={button_click}
            />
          )
        })}
      </ul>
    </section>
  )
}

export default WhatWeCanDo
