import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useBlogMetadata } from '@hooks/blog-metadata'
import parse from 'html-react-parser'

import * as cx from './BlogPostsV2.module.scss'

import backgroundImage from '/content/assets/blog/cards-v2-bg.png'
import cardBg from '/content/assets/blog/card-bg.png'

import ChevronRight from '@svg/chevronRight.inline'

const BlogPostsV2 = ({
  background = backgroundImage,
  wrapperClassName = '',
  count = 3,
  title = 'Latest Blog Posts',
  subtitle = 'Stay updated with our',
  showSubtitle = true,
}) => {
  const items = useBlogMetadata().allWpPost.edges

  const DefaultCardState = ({ item }) => {
    return (
      <div className={`${cx.item} ${cx.itemDefault}`}>
        <Link to={item.node.slug}>
          <div
            className={cx.image}
            style={{
              backgroundImage: `url(${item.node?.featuredImage?.node?.url})`,
            }}
          >
            <div className={cx.arrow}>
              <ChevronRight />
            </div>
          </div>
        </Link>
        <div className={cx.content}>
          <div className={`badge`}>
            {item.node.primaryCategory?.node?.name
              ? item.node.primaryCategory?.node?.name
              : 'Blog'}
          </div>
          <h3 className="subtitle-9">{item.node.title}</h3>
        </div>
      </div>
    )
  }

  const HoverCardState = ({ item }) => {
    return (
      <div className={`${cx.item} ${cx.itemHover}`}>
        <div
          className={cx.content}
          style={{ backgroundImage: `url(${cardBg})` }}
        >
          <div className={`badge`}>
            {item.node.primaryCategory?.node?.name
              ? item.node.primaryCategory?.node?.name
              : 'Blog'}
          </div>
          {/* <h3 className="subtitle-2">{item.node.title}</h3> */}
          <h3 className="subtitle-9">{item.node.title}</h3>
          <p className="default-body large line-clamp-5">
            {parse(item.node.excerpt)}
          </p>
          <Link to={item.node.slug} className="primary-button">
            Read More
          </Link>
        </div>
      </div>
    )
  }

  return (
    <section
      className={`${cx.section} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={cx.heading}>
        {showSubtitle && <p className="subtitle-7">{subtitle}</p>}
        <h5 className="heading-5">{title}</h5>
      </div>

      <div className={cx.items}>
        {items.map((item, index) => {
          if (index < count) {
            return (
              <div
                key={index}
                className="relative overflow-hidden w-full md:w-1/3"
              >
                <DefaultCardState item={item} />
                <HoverCardState item={item} />
              </div>
            )
          }
        })}
      </div>
    </section>
  )
}

BlogPostsV2.propTypes = {
  background: PropTypes.string,
  wrapperClassName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
}

export default BlogPostsV2
