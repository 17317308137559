import React from 'react'
import parse from 'html-react-parser'

import ElementImage from '@components/global/element/Image'
import useMedia, { media } from '@hooks/useMedia'
import clsx from 'clsx'

import SvgFacebook from '@svg/gradient-icons/facebook.inline'
import SvgInstagram from '@svg/gradient-icons/instagram.inline'
import SvgLinkedin from '@svg/gradient-icons/linkedin.inline'
import SvgPinterest from '@svg/gradient-icons/pinterest.inline'
import SvgTiktok from '@svg/gradient-icons/tiktok.inline'
import SvgTwitter from '@svg/gradient-icons/twitter.inline'

import * as cx from './Overview.module.scss'

const OverviewItem = ({
  content,
  image,
  contentTitleClassName,
  contentBoldClassName,
  contentClassName,
}) => {
  return (
    <li className={cx.overview}>
      <div className={cx.overviewBg}></div>
      <div className={cx.overviewContent}>
        <div>{image}</div>
        <div className={cx.overviewContentContainer}>
          <h1 className={contentTitleClassName}>{content.title}</h1>
          <div className={cx.content}>
            <span className={contentBoldClassName}>
              <b>{parse(content.contentBold)}&nbsp;</b>
            </span>
            <span className={contentClassName}>{parse(content.content)}</span>
          </div>
        </div>
      </div>
    </li>
  )
}

const Overview = ({
  bgImage,
  heading,
  headingClassName,
  title,
  titleClassName,
  subtitle,
  subtitleClassName,
  content,
  contentTitleClassName,
  contentBoldClassName,
  contentClassName,
}) => {
  const icons = [
    <SvgFacebook key={0} className={cx.img} />,
    <SvgInstagram key={1} className={cx.img} />,
    <SvgTiktok key={2} className={cx.img} />,
    <SvgTwitter key={3} className={cx.img} />,
    <SvgPinterest key={4} className={cx.img} />,
    <SvgLinkedin key={5} className={cx.img} />,
  ]

  return (
    <section
      id="overview"
      className={cx.wrapper}
      style={{
        backgroundImage: `url(${bgImage ? bgImage : 'none'})`,
      }}
    >
      <div className={`${cx.container} container`}>
        <div className={cx.header}>
          <h3 className={headingClassName}>{heading}</h3>
          <h1 className={titleClassName}>{title}</h1>
          <hr />
          <p className={subtitleClassName}>{subtitle}</p>
        </div>
        <div>
          <ul className={cx.descList}>
            {content?.map((item, index) => {
              return (
                <OverviewItem
                  key={index}
                  content={item}
                  image={icons[index]}
                  contentTitleClassName={contentTitleClassName}
                  contentBoldClassName={contentBoldClassName}
                  contentClassName={contentClassName}
                />
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Overview
