import React from 'react'
import clsx from 'clsx'

const SvgEdit = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 32 32' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path d="M13.5136 22H10V18.4864C9.99533 16.4962 10.7862 14.5871 12.1978 13.1842L21.3773 4H22C23.5911 4 25.1172 4.63214 26.2429 5.75714C27.3685 6.88281 28 8.40826 28 9.99933V10.6221L18.8199 19.8022C17.4156 21.2145 15.5052 22.0054 13.5136 22ZM13 19H13.5136C14.709 19.0033 15.8554 18.5279 16.6971 17.6801L24.9471 9.43013C24.8333 8.84152 24.5467 8.30112 24.1228 7.87723C23.6989 7.45335 23.1578 7.16607 22.5699 7.0529L14.3199 15.3029C13.4721 16.1446 12.9967 17.2917 13 18.4864V19Z" />
      <path d="M22 28H7C6.20446 28 5.44107 27.6839 4.87857 27.1214C4.31607 26.5589 4 25.7955 4 25V10C4 9.20446 4.31607 8.44174 4.87857 7.87857C5.44107 7.3154 6.20446 7 7 7H11.5V10H7V25H22V20.5H25V25C25 25.7955 24.6839 26.5589 24.1214 27.1214C23.5589 27.6839 22.7955 28 22 28Z" />
    </svg>
  )
}

export default SvgEdit
