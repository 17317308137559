// extracted by mini-css-extract-plugin
export var benefits = "TabContent-module--benefits--670ce";
export var benefitsContainer = "TabContent-module--benefits-container--d7262";
export var checkContainer = "TabContent-module--check-container--28938";
export var checkSvg = "TabContent-module--check-svg--12dd5";
export var contentContainer = "TabContent-module--content-container--c9e4a";
export var desc = "TabContent-module--desc--b05b0";
export var descContainer = "TabContent-module--desc-container--b6945";
export var descList = "TabContent-module--desc-list--6472f";
export var imageContainer = "TabContent-module--image-container--60734";
export var img = "TabContent-module--img--ffdd4";
export var imgDesktop = "TabContent-module--img-desktop--778cb";
export var imgMobile = "TabContent-module--img-mobile--f2285";
export var quote = "TabContent-module--quote--1ce7b";
export var textContainer = "TabContent-module--text-container--f2edb";
export var title = "TabContent-module--title--63df1";
export var wrapper = "TabContent-module--wrapper--c8e7f";