import React from 'react'
import clsx from 'clsx'

const Svg = ({ className = '' }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64 32C64 14.4 49.6 0 32 0C14.4 0 0 14.4 0 32C0 48 11.6 61.2 26.8 63.6V41.2H18.8V32H26.8V24.8C26.8 16.8 31.6 12.4 38.8 12.4C42.4 12.4 46 13.2 46 13.2V21.2H42C38 21.2 36.8 23.6 36.8 26V32H45.6L44 41.2H36.4V64C52.4 61.6 64 48 64 32Z"
        fill="url(#paint0_linear_1036_22355)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1036_22355"
          x1="-5.27341"
          y1="64"
          x2="56.4342"
          y2="80.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DF6F8" />
          <stop offset="1" stopColor="#6876D5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Svg
