import React from 'react'

const SvgCheckedCircle = ({ className = '' }) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      style={{ enableBackground: 'new 0 0 22 22' }}
      className={`fill-current ${className}`}
      fill="none"
    >
      <title>CheckedCircle</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11ZM8.25152 10.0224C8.37963 10.0774 8.49549 10.1574 8.59235 10.2577L9.95 11.6153L13.4076 8.15767C13.5045 8.05739 13.6204 7.97739 13.7485 7.92236C13.8766 7.86734 14.0144 7.83837 14.1538 7.83716C14.2932 7.83595 14.4315 7.86251 14.5605 7.91531C14.6895 7.9681 14.8068 8.04607 14.9054 8.14466C15.0039 8.24324 15.0819 8.36048 15.1347 8.48952C15.1875 8.61856 15.2141 8.75682 15.2129 8.89624C15.2116 9.03566 15.1827 9.17344 15.1277 9.30155C15.0726 9.42965 14.9926 9.54551 14.8923 9.64237L10.6923 13.8424C10.4954 14.0392 10.2284 14.1498 9.95 14.1498C9.67157 14.1498 9.40455 14.0392 9.20765 13.8424L7.10765 11.7424C7.00736 11.6455 6.92737 11.5296 6.87234 11.4015C6.81731 11.2734 6.78835 11.1357 6.78713 10.9962C6.78592 10.8568 6.81249 10.7186 6.86528 10.5895C6.91808 10.4605 6.99605 10.3432 7.09463 10.2447C7.19322 10.1461 7.31045 10.0681 7.4395 10.0153C7.56854 9.96251 7.7068 9.93595 7.84622 9.93716C7.98564 9.93837 8.12342 9.96733 8.25152 10.0224Z"
      />
    </svg>
  )
}

export default SvgCheckedCircle
