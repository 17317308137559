import React from 'react'
import clsx from 'clsx'

const SvgChat = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      style={{ enableBackground: 'new 0 0 32 32' }}
      className={clsx('fill-current', className)}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.12407 6.9686C11.0861 5.68589 13.5153 5 16 5C18.4846 5 20.9138 5.68587 22.8758 6.96853C23.4482 7.33836 23.9739 7.75832 24.4429 8.22279C26.0719 9.81764 27 11.8558 27 14C27 16.1442 26.0719 18.1823 24.4429 19.7772C23.9739 20.2417 23.4482 20.6616 22.8758 21.0315C20.9138 22.3141 18.4846 23 16 23H14.1732L5.61286 26.0573L7.16236 19.367C5.77906 17.8399 5 15.9645 5 14C5 11.8557 5.92818 9.81756 7.55719 8.22269C8.02612 7.75828 8.55181 7.33839 9.12407 6.9686ZM16 7C13.8703 7 11.827 7.5904 10.2165 8.64392L10.2115 8.64713C9.74821 8.94635 9.33005 9.2815 8.96275 9.64551L8.95818 9.65003C7.66704 10.9133 7 12.4529 7 14C7 15.5471 7.66703 17.0867 8.95817 18.35L9.35983 18.743L8.38714 22.9427L13.8268 21H16C18.1297 21 20.173 20.4096 21.7835 19.3561L21.7885 19.3529C22.2518 19.0537 22.67 18.7185 23.0373 18.3545L23.0418 18.35C24.333 17.0867 25 15.5471 25 14C25 12.4529 24.333 10.9133 23.0418 9.65002L23.0372 9.64552C22.6699 9.28151 22.2518 8.94633 21.7885 8.64712L21.7835 8.64393C20.173 7.59041 18.1297 7 16 7Z"
      />
    </svg>
  )
}

export default SvgChat
