import React from 'react'
import clsx from 'clsx'

const Svg = ({ className = '' }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4 64H0.799988V21.2H14.4V64ZM7.60001 15.2C3.20001 15.2 0 12 0 7.6C0 3.2 3.60001 0 7.60001 0C12 0 15.2 3.2 15.2 7.6C15.2 12 12 15.2 7.60001 15.2ZM64 64H50.4V40.8C50.4 34 47.6 32 43.6 32C39.6 32 35.6 35.2 35.6 41.2V64H22V21.2H34.8V27.2C36 24.4 40.8 20 47.6 20C55.2 20 63.2 24.4 63.2 37.6V64H64Z"
        fill="url(#paint0_linear_1036_22402)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1036_22402"
          x1="-5.27341"
          y1="64"
          x2="56.4342"
          y2="80.812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DF6F8" />
          <stop offset="1" stopColor="#6876D5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Svg
