import React from 'react'
import parse from 'html-react-parser'

import ElementImage from '@components/global/element/Image'
import SvgCheckedCircle from '@svg/checkedCircle.inline'
import useMedia, { media } from '@hooks/useMedia'
import clsx from 'clsx'

import * as cx from './TabContent.module.scss'

const TabItem = ({ item, className }) => {
  return (
    <li className={cx.benefits}>
      <div className={cx.checkContainer}>
        <SvgCheckedCircle className={cx.checkSvg} />
      </div>
      <div className={cx.textContainer}>
        <p className={className}>{parse(item)}</p>
      </div>
    </li>
  )
}

const TabContent = ({
  bgImage,
  img,
  imgM,
  heading,
  headingMobile,
  headingClassName,
  headingDesc,
  headingDescClassName,
  headingSub,
  headingSubClassName,
  benefitsHeader,
  benefitsHeaderClassName,
  benefitsDescList,
  benefitsDescClassName,
}) => {
  /*
  const desktop = useMedia(media.desktop)
  const tablet = useMedia(media.minTablet)
  const image = desktop ? img : imgM

  let cWidth = 414
  let cHeight = 381

  if (desktop) {
    cWidth = 645
    cHeight = 437
  } else if (tablet) {
    cWidth = 416
    cHeight = 356
  } else {
    cWidth = 414
    cHeight = 381
  } */

  return (
    <section
      id="tabs-content"
      className={cx.wrapper}
      style={{ backgroundImage: `url(${bgImage ? bgImage : 'none'})` }}
    >
      <div className={cx.title}>
        <h1 className={headingClassName}>{parse(heading)}</h1>
      </div>
      <div className={cx.contentContainer}>
        <div className={cx.imageContainer}>
          <canvas width={414} height={381}></canvas>
          <ElementImage
            className={clsx(cx.img, cx.imgDesktop)}
            src={img}
            alt={'Device Image'}
          />
          <ElementImage
            className={clsx(cx.img, cx.imgMobile)}
            src={imgM}
            alt={'Device Image'}
          />
        </div>
        <div className={cx.descContainer}>
          <div className={cx.desc}>
            <p className={headingDescClassName}>{parse(headingDesc)}</p>
          </div>
          <div className={cx.quote}>
            <p className={headingSubClassName}>{headingSub}</p>
          </div>
        </div>
      </div>
      <hr />
      <div className={cx.benefitsContainer}>
        <h1 className={benefitsHeaderClassName}>{parse(benefitsHeader)}</h1>
        <ul className={cx.descList}>
          {benefitsDescList?.map((item, index) => {
            return (
              <TabItem
                key={index}
                item={item}
                className={benefitsDescClassName}
              />
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default TabContent
