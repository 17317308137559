import React from 'react'
import clsx from 'clsx'

const Svg = ({ className = '' }) => {
  return (
    <svg
      width="66"
      height="64"
      viewBox="0 0 66 64"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.2014 0C15.108 0 0.304352 14.7692 0.304352 32.8205C0.304352 46.359 8.52864 57.8461 20.0426 62.7692C20.0426 60.3077 20.0426 57.8462 20.4538 55.3846C21.2762 52.5128 24.5659 37.3333 24.5659 37.3333C24.5659 37.3333 23.3323 35.2821 23.3323 32C23.3323 27.0769 26.2108 23.3846 29.5005 23.3846C32.379 23.3846 34.0239 25.4359 34.0239 28.3077C34.0239 31.1795 31.9678 35.6923 31.1454 39.7949C30.3229 43.0769 32.7902 45.9487 36.4912 45.9487C42.6594 45.9487 46.7715 38.1538 46.7715 28.3077C46.7715 20.9231 41.8369 15.5897 33.2014 15.5897C23.3323 15.5897 17.1641 22.9744 17.1641 31.1795C17.1641 34.0513 17.9866 36.1026 19.2202 37.7436C19.6314 38.5641 20.0426 38.5641 19.6314 39.3846C19.6314 39.7949 19.2202 41.4359 18.809 41.8462C18.3978 42.6667 17.9866 43.0769 17.1641 42.6667C12.6408 40.6154 10.5847 35.6923 10.5847 29.9487C10.5847 20.5128 18.3977 9.4359 34.0239 9.4359C46.7715 9.4359 54.9957 18.4615 54.9957 28.3077C54.9957 41.0256 48.0051 50.8718 37.3136 50.8718C33.6127 50.8718 30.3229 48.8205 29.0893 46.7692C29.0893 46.7692 27.0332 54.1538 26.622 55.7949C25.7996 58.2564 24.566 60.7179 23.3323 62.7692C26.2108 63.5897 29.5005 64 32.7902 64C50.8836 64 65.6873 49.2308 65.6873 31.1795C66.0985 14.7692 51.2948 0 33.2014 0Z"
        fill="url(#paint0_linear_1036_22403)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1036_22403"
          x1="-5.0837"
          y1="64"
          x2="57.7745"
          y2="81.4978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DF6F8" />
          <stop offset="1" stopColor="#6876D5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Svg
